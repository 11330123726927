import type {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { LoadingState } from '@/modules/shared/types/state.type';
import { RootState } from '@/store/type';
import { getVideoLighthouseDimensions, updateVideoLighthouseDimensions } from '../services/api';
import type {
  LighthouseIntegrationState,
  MetadataKey,
  LighthouseCustomMetadataItem,
  MappedCustomDimensionData,
} from '../types';

type LighthouseIntegrationContext = ActionContext<LighthouseIntegrationState, RootState>;

export const state: LighthouseIntegrationState = {
  customDimensionData: null,
  mappedCustomDimensionData: {
    d1: null,
    d2: null,
    d3: null,
    d4: null,
    d5: null,
    d6: null,
    d7: null,
    d8: null,
    d9: null,
    d10: null,
  },
  loadCustomDimensionDataState: setStateLoadingStatusByType(),
  saveCustomDimensionDataState: setStateSavingStatusByType(),
};

export const getters: GetterTree<LighthouseIntegrationState, RootState> = {
  isCustomDimensionDataSyncing: (state: LighthouseIntegrationState): boolean => state.customDimensionData?.status === 'syncing',
  isCustomDimensionDataSyncFailed: (state: LighthouseIntegrationState): boolean => state.customDimensionData?.status === 'failedOnLastSync',
};

export const mutations: MutationTree<LighthouseIntegrationState> = {
  setCustomDimensionData(state: LighthouseIntegrationState, data: LighthouseIntegrationState['customDimensionData']) {
    state.customDimensionData = data;
  },
  destroyCustomDimensionData(state: LighthouseIntegrationState) {
    state.customDimensionData = null;
  },
  setLoadCustomDimensionDataState(state: LighthouseIntegrationState, loadingState: LoadingState) {
    state.loadCustomDimensionDataState = setStateLoadingStatusByType(loadingState);
  },
  setSaveCustomDimensionDataState(state: LighthouseIntegrationState, loadingState: LoadingState) {
    state.saveCustomDimensionDataState = setStateSavingStatusByType(loadingState);
  },
  setMappedCustomDimensionData(state: LighthouseIntegrationState, data: LighthouseIntegrationState['mappedCustomDimensionData']) {
    state.mappedCustomDimensionData = data;
  },
  setMappedCustomDimensionDataByKey(state: LighthouseIntegrationState, data: { key: MetadataKey, value: LighthouseCustomMetadataItem['customField'] }) {
    state.mappedCustomDimensionData = {
      ...state.mappedCustomDimensionData,
      [data.key]: data.value,
    };
  },
};

export const actions: ActionTree<LighthouseIntegrationState, RootState> = {
  async loadCustomDimensionData({ commit }: LighthouseIntegrationContext) {
    commit('setLoadCustomDimensionDataState', { type: 'loading' });
    try {
      const response = await getVideoLighthouseDimensions();
      const { customMetaData } = response.data;

      commit('setCustomDimensionData', response.data);
      commit('setMappedCustomDimensionData', customMetaData.reduce((previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.key]: currentValue.customField,
      }), state.mappedCustomDimensionData));

      commit('setLoadCustomDimensionDataState', { type: 'success' });
    } catch (error) {
      commit('setLoadCustomDimensionDataState', {
        type: 'error',
        error: formatErrorObject(error, 'Lighthouse Integration'),
      });
    }
  },
  async saveCustomDimensionData({ commit, dispatch }: LighthouseIntegrationContext, payload: MappedCustomDimensionData) {
    commit('setSaveCustomDimensionDataState', { type: 'saving' });
    try {
      const response = await updateVideoLighthouseDimensions({
        customMetaData: Object.keys(payload).map((key) => {
          const metadataKey = key as MetadataKey;

          return {
            key: metadataKey,
            customFieldId: payload[metadataKey]?.id ?? null,
          };
        }),
      });
      commit('setCustomDimensionData', response.data);

      commit('setSaveCustomDimensionDataState', { type: 'success' });
      await dispatch('loadCustomDimensionData');
    } catch (error) {
      commit('setSaveCustomDimensionDataState', {
        type: 'error',
        error: formatErrorObject(error, 'Lighthouse Integration'),
      });
    }
  },
  destroyLighthouseIntegrationState({ commit }: LighthouseIntegrationContext) {
    commit('destroyCustomDimensionData');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
